<template>
  <div class="fluid" id="mmslist">
    <div>
      <div class="mms-list-div">
        <v-card elevation="0" class="px-3 py-2">
          <v-row no-gutters>
            <v-col
              cols="12"
              class="d-flex"
              style="position:relative;padding:0 20px;text-align:center;"
            >
              <h4 class="indigo--text" style="font-weight:bold;margin:auto;">
                MONITORING MACHINE SYSTEM
              </h4>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            justify="center"
            style="
            width:60%;
      margin: auto;
      padding: 10px 0; margin-top:150px;"
          >
            <v-col
              cols="6"
              md="3"
              v-for="(item, i) in apps"
              :key="i"
              class="home-tab-container"
            >
              <!-- :hidden="item.sort === 'HRS' && build !== 'development'" -->
              <!-- <div v-for="(item, i) in apps" :key="i" class="home-tab-container"> -->
              <div style="width:90px; margin:auto;">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 3 : 2"
                    :class="({ 'on-hover': hover }, 'mx-auto', 'home-tab')"
                    @click="routerPush(item.name, item.id, item.path)"
                  >
                    <v-list-item style="padding:10px;">
                      <v-list-item-avatar
                        tile
                        size="60"
                        :color="item.color"
                        style="margin:auto;box-shadow:inset 2px 2px 6px rgba(0,0,0,0.8);"
                      >
                        <v-img
                          v-if="item.asset"
                          contain
                          :src="item.icon"
                          :style="
                            `height:${item.height};width:${item.width}; filter: invert(50%) sepia(50%) saturate(50%) hue-rotate(90deg) brightness(200%) contrast(200%) drop-shadow(5px 5px 2px rgb(0 0 0 / 0.5));`
                          "
                        />
                        <v-icon
                          v-else
                          color="white"
                          x-large
                          style="filter: drop-shadow(8px 5px 2px rgb(0 0 0 / 0.5))"
                        >
                          {{ item.icon }}
                        </v-icon>
                        <!-- <v-img v-else :src="item.icon"/></v-img> -->
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-card>
                </v-hover>
              </div>
              <p class="home-tab-alias">
                {{ item.alias }}
              </p>
              <!-- </div> -->
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
// import buildType from '../../services/buildType'
import { mapMutations } from 'vuex'
export default {
  name: 'mmsdashboard',
  data: () => ({
    build: process.env.VUE_APP_BUILD_TYPE,
    tabs: [],
    apps: [
      {
        name: 'MMS',
        alias: 'MMS',
        code: 'MMS',
        id: 22,
        path: '/mms/oee',
        icon: 'mdi-speedometer-slow',
        color: '#8f2d56'
      },
      {
        name: 'MASTER DATA',
        alias: 'Master Data',
        code: 'MSD',
        id: 22,
        path: '/mms/mastermachine',
        icon: 'mdi-rhombus-split',
        color: '#8f2d56'
      }
    ]
  }),

  async mounted() {
    this.setArrTabs()
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setTabs']),
    setArrTabs() {
      const tabsArr = [
        {
          tab_name: 'MMS',
          path: '/mms'
        }
      ]

      this.tabs = tabsArr
      this.setTabs(tabsArr)
    },
    // ...mapActions(['dropdownPlant']),
    routerPush(name, id, path) {
      // const msg = 'You cannot access this feature'
      switch (name) {
        case 'MMS':
          // if (this.checkAppID(id) !== undefined) {
          console.log(this.build)
          // if (this.build === 'development' || this.build === 'staging') {
          this.$router.push(path)
          // } else {
          //   this.showMsgDialog(
          //     'warning',
          //     'Menu ini masih dalam mode development, Beralih ke situs trial untuk melihat',
          //     true
          //   ).then(res => {
          //     if (res.isConfirmed) {
          //       window.location.href = 'http://10.10.20.6:7352/mms/oee'
          //       // window.open('http://10.10.20.6:7352/oee')
          //     }
          //   })
          // }
          // } else {
          //   this.showMsgDialog('warning', msg)
          // }
          break
        case 'MASTER DATA':
          // if (this.checkAppID(id) !== undefined) {
          this.$router.push(path)
          // } else {
          //   this.showMsgDialog('warning', msg)
          // }
          break
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style lang="scss">
.v-card:not(.on-hover) {
  opacity: 1;
}
.home-tab-container {
  // margin-left: 60px;
  // margin-right: 60px;
  padding: 0 20px;
  height: 150px;
  &:hover .home-tab-alias {
    text-shadow: 2px 2px 3px #dddddd;
  }
  .home-tab {
    cursor: pointer;
    // width: 90%;
    .home-tab-title {
      font-size: 20px;
    }
  }
  .home-tab-alias {
    margin: auto;
    text-align: center;
    width: 90px;
    font-size: 16px;
    margin-top: 5px;
    // text-shadow: 2px 1.5px 3px #aaaaaa;
  }
}
</style>
